var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('go-back-btn',{attrs:{"routeToGo":_vm.routeToGo}}),_c('PageHeader',{staticClass:"mb-3",attrs:{"title":_vm.title}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-2",attrs:{"flat":"","outlined":""}},[_c('v-card-text',[_c('v-form',{ref:"corte-form",attrs:{"id":"corte-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"3"}},[_c('v-switch',{staticClass:"mt-3",attrs:{"label":"Solo clientes activos"},model:{value:(_vm.soloClientesActivos),callback:function ($$v) {_vm.soloClientesActivos=$$v},expression:"soloClientesActivos"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"4"}},[_c('v-switch',{staticClass:"mt-3",attrs:{"label":"Excluir clientes con licencia perpetua"},model:{value:(_vm.excluirClientesLicencia),callback:function ($$v) {_vm.excluirClientesLicencia=$$v},expression:"excluirClientesLicencia"}})],1),_c('v-col',{staticClass:"mt- 2py-0",attrs:{"cols":"3","sm":"3","md":"3","align-self":"center"}},[_c('v-select',{attrs:{"dense":"","outlined":"","multiple":"","items":_vm.anioItems,"item-text":"value","item-value":"value","autocomplete":"on","clearable":"","rules":_vm.rules.validAnio,"label":"Año"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{staticClass:"mt-1"},[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.anio.length - 1)+" otros) ")]):_vm._e()]}}]),model:{value:(_vm.anio),callback:function ($$v) {_vm.anio=$$v},expression:"anio"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"2","sm":"2","md":"2","align-self":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"2","small":"","type":"submit","form":"corte-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1)],1),_c('div',{class:_vm.datosFechasCorte.length > 0 ? 'mt-5 mb-10' : 'mt-2 mb-10'},[_c('v-card',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.datosFechasCorteHeaders,"items":_vm.datosFechasCorte,"loading":_vm.loading,"no-data-text":"No hay datos disponibles para mostrar","search":_vm.searchConsulta},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"9","align-self":"center"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Buscar","single-line":"","append-icon":_vm.searchIcon},model:{value:(_vm.searchConsulta),callback:function ($$v) {_vm.searchConsulta=$$v},expression:"searchConsulta"}})],1),_c('v-col',{staticClass:"pt-5 mt-2",attrs:{"cols":"3","sm":"3","align":"end"}},[(_vm.canCreateNewFecha)?_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.openModalNuevaFecha()}}},[_vm._v(" Nueva fecha de corte ")]):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canDeleteFechaCorte)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.scriptId > 0)?_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.eliminarFechaCorte(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Eliminar fecha de corte")])]):_vm._e()]}},{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"fontColor",staticStyle:{"text-align":"right"},attrs:{"colspan":"3"}}),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"1"}},[_vm._v("|")]),_c('th',{staticClass:"fontColor",staticStyle:{"text-align":"center"},attrs:{"colspan":"2"}},[_vm._v(" Resultado script automático ")]),_c('th',{staticStyle:{"text-align":"left"},attrs:{"colspan":""}},[_vm._v("|")])])])]},proxy:true},{key:"item.cantClientes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({style:({
                  color: item.rojoCantClientes ? 'red' : '',
                  textDecoration: 'underline',
                }),on:{"click":function($event){return _vm.verDetalleCantClientes(item)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.cantClientes)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver clientes por fecha")])])]}},{key:"item.scriptId",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.scriptId != 0),expression:"item.scriptId != 0"}],style:({ textDecoration: 'underline' }),on:{"click":function($event){return _vm.verScript(item)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.scriptId)+" ")])]}}],null,true)},[_c('span',[_vm._v(" Ver script")])])]}},{key:"item.resultadoPendiente",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({style:({
                  color: item.rojoResultadoPendiente ? 'red' : '',
                  textDecoration: 'underline',
                }),on:{"click":function($event){return _vm.verDetallePendientes(item)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.resultadoPendiente)+" ")])]}}],null,true)},[_c('span',{style:(item.resultadoPendiente ? 'color:red' : '')},[_vm._v(" Ver detalle pendientes")])])]}},{key:"item.resultadoOk",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({style:({ textDecoration: 'underline' }),on:{"click":function($event){return _vm.verDetalleOk(item)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.resultadoOk)+" ")])]}}],null,true)},[_c('span',[_vm._v(" Ver detalle Ok")])])]}},{key:"item.resultadoError",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({style:({ textDecoration: 'underline' }),on:{"click":function($event){return _vm.verDetalleError(item)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.resultadoError)+" ")])]}}],null,true)},[_c('span',[_vm._v(" Ver detalle error")])])]}}],null,true)}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.openModalScript),callback:function ($$v) {_vm.openModalScript=$$v},expression:"openModalScript"}},[_c('v-card',[_c('v-card-title'),_c('v-col',{staticClass:"py-0 pl-5",attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"dense":"","outlined":"","label":"Script","type":"text","readonly":"","no-resize":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.copyScript()}}},on),[_c('v-icon',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.copied ? "mdi-check" : "mdi-content-copy"))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])]},proxy:true}]),model:{value:(_vm.script),callback:function ($$v) {_vm.script=$$v},expression:"script"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModalScript()}}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)],1),(_vm.openModalNewFecha)?_c('v-dialog',{attrs:{"max-width":"45%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModalNewFecha.apply(null, arguments)}},model:{value:(_vm.openModalNewFecha),callback:function ($$v) {_vm.openModalNewFecha=$$v},expression:"openModalNewFecha"}},[_c('NewFechaCorte',{on:{"closeModalNewFecha":_vm.closeModalNewFecha}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDeleteFechaDeCorte,"openDelete":_vm.showDeleteModalFechaCorte,"maxWidth":'37%'},on:{"update:openDelete":function($event){_vm.showDeleteModalFechaCorte=$event},"update:open-delete":function($event){_vm.showDeleteModalFechaCorte=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }