<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="generarScript()"
        >
          <v-row>
            <!-- Fecha  -->
            <v-col cols="4" sm="6" md="5" class="pb-0">
              <v-menu
                ref="menu-fecha-vigencia"
                v-model="menuNuevaFecha"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaNuevaSelected"
                    label="Fecha de corte"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    outlined
                    :filled="tipoTexto != null"
                    :disabled="tipoTexto != null"
                    clearable
                    dense
                    hint="Formato DD/MM/AAAA"
                    v-mask="'##/##/####'"
                    @change="cleanTime()"
                    @blur="fechaNueva = parseDateToIso(fechaNuevaSelected)"
                    v-on="on"
                    :rules="
                      fechaNueva == null ? ['Campo requerido'] : rules.validDate
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaNueva"
                  no-title
                  @change="fechaNuevaSelected = formatDate(fechaNueva)"
                  @input="menuNuevaFecha = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" md="4" class="pb-0">
              <v-text-field
                outlined
                dense
                v-model="time"
                :filled="tipoTexto != null"
                :disabled="tipoTexto != null"
                v-mask="'##:##'"
                ref="time"
                label="Hora"
                append-icon="mdi-clock-time-four-outline"
                :rules="time ? [rules.validTime] : ['Campo requerido']"
              ></v-text-field>
            </v-col>
            <v-col cols="1" md="1" sm="1" class="py-0 px-0">
              <v-tooltip right max-width="50rem">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="1.4rem"
                    class="pt-4"
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                  >
                    {{ infoIcon }}</v-icon
                  >
                </template>
                <span>
                  {{ infoText }}
                </span>
              </v-tooltip>
            </v-col>

            <v-col cols="1" md="1" sm="1" class="py-2 px-0">
              <v-card-actions>
                <v-btn
                  class="mr-1"
                  type="submit"
                   :filled="tipoTexto != null"
                  :disabled="tipoTexto != null ||
                    !fechaNuevaSelected ||
                    time == null ||
                    !isValidTimeFormat(time)
                  "
                  :loading="loadingBtnScript"
                  color="primary"
                  form="form"
                >
                  Crear
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row>
            <!-- Tipo de texto -->
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                tabindex="2"
                v-model="tipoTexto"
                label="Script"
                dense
                auto-grow
                readonly
                rows="6"
                outlined
              >
                <template #append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :filled="tipoTexto == null"
                        :disabled="tipoTexto == null"
                        icon
                        @click="copyScript()"
                        v-on="on"
                      >
                        <v-icon class="mb-3">{{
                          copied ? "mdi-check" : "mdi-content-copy"
                        }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ tooltipText }}</span>
                  </v-tooltip>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" outlined @click="closeModal"> Cerrar </v-btn>
        <!-- <v-btn
          class="mr-2"
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn> -->
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
export default {
  name: "NewFechaCorte",
  directives: { mask },
  data: () => ({
    formTitle: "Nueva fecha de corte",
    infoIcon: enums.icons.SNB_INFO,
    calendarIcon: "mdi-calendar",
    time: null,
    rules: rules,
    menuNuevaFecha: false,
    fechaNueva: null,
    copied: false,
    fechaNuevaSelected: null,
    isFormValid: false,
    esVigente: true,
    tipoTexto: null,
    loadingSaveBtn: false,
    loadingBtnScript: false,
    infoText: null,
  }),
  created() {
    this.traerMensajeFecha();
  },
  computed: {
    tooltipText() {
      return this.copied ? "Script copiado" : "Copiar script";
    },
  },
  methods: {
    ...mapActions({
      getMensajeInfoNewFecha: "clientesData/getMensajeInfoNewFecha",
      postFechaCorteGral: "clientesData/postFechaCorteGral",
      setAlert: "user/setAlert",
    }),
    // copyScript() {
    //   const textArea = document.createElement("textarea");
    //   textArea.value = this.tipoTexto;
    //   // Establecer el área de texto fuera del área visible de la página
    //   textArea.style.position = "fixed";
    //   textArea.style.top = "0";
    //   textArea.style.left = "0";
    //   textArea.style.width = "2em";
    //   textArea.style.height = "2em";
    //   textArea.style.padding = "0";
    //   textArea.style.border = "none";
    //   textArea.style.outline = "none";
    //   textArea.style.boxShadow = "none";
    //   textArea.style.background = "transparent";
    //   document.body.appendChild(textArea);
    //   textArea.select();
    //   try {
    //     const successful = document.execCommand("copy");
    //     this.copied = successful;
    //     if (successful) {
    //       setTimeout(() => {
    //         this.copied = false;
    //       }, 2000);
    //     }
    //   } catch (err) {
    //     console.error("Error copiando script:", err);
    //   }
    //   document.body.removeChild(textArea);
    // },
    copyScript() {
      try {
        navigator.clipboard
          .writeText(this.tipoTexto)
          .then(() => {
            this.copied = true;
            setTimeout(() => {
              this.copied = false;
            }, 2000);
          })
          .catch((err) => {
            console.error("Error copiando script:", err);
          });
      } catch (err) {
        console.error("Error copiando script:", err);
      }
    },
    newAccesoDatos() {
      this.formTitle = "Nuevos datos de acceso";
    },
    async traerMensajeFecha() {
      const resp = await this.getMensajeInfoNewFecha();
      this.infoText = resp;
    },
    async generarScript() {
      try {
        const tiempo = !this.time ? "00:00" : this.time.toString();
        this.loadingBtnScript = true;
        let payload = {
          fechaCorte: `${this.fechaNueva}T${tiempo}:00.732Z`,
        };
        const response = await this.postFechaCorteGral(payload);
        this.tipoTexto = response.data.data;
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Script generado con éxito.",
          });
          this.loadingBtnScript = false;
        }
      } catch (error) {
        this.loadingBtnScript = false;
      }
    },
    cleanTime() {
      if (this.fechaNuevaSelected == null) {
        this.time = null;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal() {
      this.$emit("closeModalNewFecha");
    },
    isValidTimeFormat(time) {
      // Puedes implementar tu propia lógica para verificar el formato del tiempo
      const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      return timeRegex.test(time);
    },
  },
};
</script>

<style scoped></style>
