var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.generarScript()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","sm":"6","md":"5"}},[_c('v-menu',{ref:"menu-fecha-vigencia",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha de corte","append-icon":_vm.calendarIcon,"outlined":"","filled":_vm.tipoTexto != null,"disabled":_vm.tipoTexto != null,"clearable":"","dense":"","hint":"Formato DD/MM/AAAA","rules":_vm.fechaNueva == null ? ['Campo requerido'] : _vm.rules.validDate},on:{"change":function($event){return _vm.cleanTime()},"blur":function($event){_vm.fechaNueva = _vm.parseDateToIso(_vm.fechaNuevaSelected)}},model:{value:(_vm.fechaNuevaSelected),callback:function ($$v) {_vm.fechaNuevaSelected=$$v},expression:"fechaNuevaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuNuevaFecha),callback:function ($$v) {_vm.menuNuevaFecha=$$v},expression:"menuNuevaFecha"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaNuevaSelected = _vm.formatDate(_vm.fechaNueva)},"input":function($event){_vm.menuNuevaFecha = false}},model:{value:(_vm.fechaNueva),callback:function ($$v) {_vm.fechaNueva=$$v},expression:"fechaNueva"}})],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3","md":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],ref:"time",attrs:{"outlined":"","dense":"","filled":_vm.tipoTexto != null,"disabled":_vm.tipoTexto != null,"label":"Hora","append-icon":"mdi-clock-time-four-outline","rules":_vm.time ? [_vm.rules.validTime] : ['Campo requerido']},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1),_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"1","md":"1","sm":"1"}},[_c('v-tooltip',{attrs:{"right":"","max-width":"50rem"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pt-4",attrs:{"size":"1.4rem","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon))])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.infoText)+" ")])])],1),_c('v-col',{staticClass:"py-2 px-0",attrs:{"cols":"1","md":"1","sm":"1"}},[_c('v-card-actions',[_c('v-btn',{staticClass:"mr-1",attrs:{"type":"submit","filled":_vm.tipoTexto != null,"disabled":_vm.tipoTexto != null ||
                  !_vm.fechaNuevaSelected ||
                  _vm.time == null ||
                  !_vm.isValidTimeFormat(_vm.time),"loading":_vm.loadingBtnScript,"color":"primary","form":"form"}},[_vm._v(" Crear ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"tabindex":"2","label":"Script","dense":"","auto-grow":"","readonly":"","rows":"6","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"filled":_vm.tipoTexto == null,"disabled":_vm.tipoTexto == null,"icon":""},on:{"click":function($event){return _vm.copyScript()}}},on),[_c('v-icon',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.copied ? "mdi-check" : "mdi-content-copy"))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])]},proxy:true}]),model:{value:(_vm.tipoTexto),callback:function ($$v) {_vm.tipoTexto=$$v},expression:"tipoTexto"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cerrar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }